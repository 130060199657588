import React from "react";
import AppRoutes from "./routes/Approutes";

const App = () => {
  return (
      <div>
          <AppRoutes/>
      </div>
  );
};

export default App;
