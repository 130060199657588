// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9; /* Color de fondo */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 0 auto;
}

.login-form {
    width: 100%;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
}

.form-control{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/network/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB,EAAE,mBAAmB;IAC9C,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB","sourcesContent":[".login-container-component {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: #f9f9f9; /* Color de fondo */\n    padding: 20px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 400px;\n    margin: 0 auto;\n}\n\n.login-form {\n    width: 100%;\n}\n\n.form-group {\n    margin-bottom: 15px;\n    text-align: left;\n}\n\n.form-group label {\n    display: block;\n    margin-bottom: 5px;\n    font-size: 16px;\n    font-weight: bold;\n}\n\n.form-control{\n    width: 100%;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
