// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General layout styles */
.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f8f9fa; /* Color de fondo suave */
    font-family: Arial, sans-serif; /* Fuente moderna */
    color: #333; /* Color del texto */
    margin: 0; /* Elimina márgenes externos */
    padding: 0; /* Elimina relleno externo */
}

.breadcrumb-item a {
    color: #333; /* Cambia el color de los enlaces a un color deseado */
    text-decoration: none; /* Elimina el subrayado */
}

.breadcrumb-item a:hover {
    text-decoration: underline; /* Agrega subrayado al pasar el ratón, si lo deseas */
}
`, "",{"version":3,"sources":["webpack://./src/routes/LayoutControl/Layout.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,yBAAyB,EAAE,yBAAyB;IACpD,8BAA8B,EAAE,mBAAmB;IACnD,WAAW,EAAE,oBAAoB;IACjC,SAAS,EAAE,8BAA8B;IACzC,UAAU,EAAE,4BAA4B;AAC5C;;AAEA;IACI,WAAW,EAAE,sDAAsD;IACnE,qBAAqB,EAAE,yBAAyB;AACpD;;AAEA;IACI,0BAA0B,EAAE,qDAAqD;AACrF","sourcesContent":["/* General layout styles */\n.layout {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n    background-color: #f8f9fa; /* Color de fondo suave */\n    font-family: Arial, sans-serif; /* Fuente moderna */\n    color: #333; /* Color del texto */\n    margin: 0; /* Elimina márgenes externos */\n    padding: 0; /* Elimina relleno externo */\n}\n\n.breadcrumb-item a {\n    color: #333; /* Cambia el color de los enlaces a un color deseado */\n    text-decoration: none; /* Elimina el subrayado */\n}\n\n.breadcrumb-item a:hover {\n    text-decoration: underline; /* Agrega subrayado al pasar el ratón, si lo deseas */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
